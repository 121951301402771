import React from "react"

const Paint = () =>{
    return(
        <svg x="0" y="0" fill="currentColor" viewBox="0 0 512 512">
            <path d="M183.2 99c-6.8-2.8-13.9-4.3-21.3-4.3-22.3 0-42.2 13.3-50.9 33.8-11.7 28 1.5 60.4 29.6 72.1 6.8 2.8 13.9 4.3 21.3 4.3 22.3 0 42.2-13.3 50.9-33.8 11.7-28-1.5-60.4-29.6-72.1zm15.8 66.3c-6.3 15-20.8 24.6-37 24.6-5.3 0-10.5-1.1-15.5-3.1-20.4-8.5-30.1-32.1-21.5-52.5 6.3-15 20.8-24.6 37-24.6 5.3 0 10.5 1 15.5 3.1 20.3 8.6 30 32.1 21.5 52.5zM76.5 324c10.4 10.6 24.4 16.4 39.2 16.4 14.6 0 28.3-5.6 38.7-15.9 21.6-21.3 21.9-56.3.6-77.9-10.4-10.6-24.4-16.4-39.2-16.4-14.6 0-28.3 5.6-38.7 15.9-21.7 21.3-22 56.3-.6 77.9zm39.2-78.7c10.8 0 21 4.2 28.6 12 15.5 15.8 15.3 41.2-.4 56.7-7.6 7.4-17.5 11.5-28.1 11.5-10.8 0-21-4.2-28.6-12-15.5-15.8-15.3-41.2.4-56.7 7.5-7.4 17.5-11.5 28.1-11.5zM188.1 348c-28.2 11.3-41.9 43.5-30.6 71.7 8.4 21 28.5 34.6 51.2 34.6 7 0 13.9-1.3 20.5-4 28.2-11.3 41.9-43.5 30.6-71.7-8.4-21-28.5-34.6-51.2-34.6-7 0-13.9 1.4-20.5 4zm57.7 36.2c8.2 20.5-1.8 43.9-22.3 52.2-4.8 1.9-9.8 2.9-14.9 2.9-16.5 0-31.1-9.9-37.2-25.2-8.2-20.5 1.8-43.9 22.3-52.2 4.8-1.9 9.8-2.9 14.9-2.9 16.5 0 31.1 9.9 37.2 25.2zM213 272.6v-11.4c0-4.1-3.4-7.5-7.5-7.5s-7.5 3.4-7.5 7.5v11.4c0 4.1 3.4 7.5 7.5 7.5s7.5-3.4 7.5-7.5zM339.4 272.6v-11.4c0-4.1-3.4-7.5-7.5-7.5s-7.5 3.4-7.5 7.5v11.4c0 4.1 3.4 7.5 7.5 7.5s7.5-3.4 7.5-7.5zM268.7 286.2c8.2 0 16-3.5 21.2-9.5 2.7-3.1 2.4-7.9-.7-10.6s-7.9-2.4-10.6.7c-2.4 2.8-6 4.3-10 4.3-3.9 0-7.5-1.6-10-4.3-2.7-3.1-7.5-3.4-10.6-.7s-3.4 7.5-.7 10.6c5.4 6 13.1 9.5 21.4 9.5z"></path>
            <path d="M339.6 132.1c1-25.9 8.6-51.9 21.4-74.4l.3-.6c9.5-16.6 20.7-29.5 31-39 2.8-2.6 6.3-3.6 9.7-2.8 3.8.9 6.9 3.9 8.5 8.1 6 16.3 15.3 31.8 27.5 46.1 2.7 3.2 7.4 3.5 10.6.8 3.2-2.7 3.5-7.4.8-10.6-11.1-13-19.4-27-24.9-41.6C421.2 9.2 414 2.6 405.4.6c-8.3-2-16.8.4-23.3 6.5-10.1 9.4-21 21.7-30.6 37.3-29-12.5-59.8-19-91.8-19.4-64.6-.8-125.4 23.7-171.3 69C42.3 139.3 17 199.8 17 264.4c0 54.9 19.1 108.6 53.7 151.1 2.6 3.2 7.3 3.7 10.6 1.1 3.2-2.6 3.7-7.3 1.1-10.6C49.9 366.1 32 315.8 32 264.4c0-60.5 23.7-117.2 66.8-159.7 43-42.5 100.1-65.5 160.6-64.7 29.4.4 57.9 6.3 84.6 17.6-6 11.6-11 24.7-14.5 39.4-9.2-6.5-20.2-10.1-31.8-10.1-30.4 0-55.1 24.7-55.1 55.1s24.7 55.1 55.1 55.1c14.6 0 28.2-5.7 38.4-15.6 2.1 3.6 4.6 7.1 7.3 10.4 3.2 4 6.8 7.6 10.7 11l8.4 165.4L337 381c-19.2 9.6-32.2 28.9-33.8 50.3l-1.1 15.1c-1.8 23.7-21.7 42.4-45.3 42.4h-.5c-57.7 0-112.5-21.8-154.3-61.5-3-2.8-7.8-2.7-10.6.3-2.9 3-2.7 7.8.3 10.6 44.6 42.3 103.1 65.6 164.6 65.6h.5c31.4-.1 57.8-24.8 60.2-56.3l1.1-15.1c1.2-16.2 11-30.8 25.6-38.1l19.6-9.8L368 478c1 19 16.6 33.9 35.7 33.9s34.7-14.9 35.7-33.9l6.8-134.9 14.7-7.4c19.2-9.6 31.8-28.1 33.7-49.5.7-7.2 1-14.6 1-21.9 0-32.8-6.5-64.5-19.2-94.3 11.1-27.6 8-60.8-7.4-86.1-2.2-3.5-6.8-4.6-10.3-2.5-3.5 2.2-4.6 6.8-2.5 10.3 13.4 22 15.9 51.6 5.2 75.2-10.4 23.1-34.2 39.9-59.9 39.2-38.6-1.1-63.4-37.6-61.9-74zm-41.8 50c-22.1 0-40.1-18-40.1-40.1s18-40.1 40.1-40.1c10.8 0 21 4.3 28.6 12 .2.2-.3 2.4-.4 2.8-2.1 16.9-2.4 33.9 3.3 50.1-7.6 9.6-19.1 15.3-31.5 15.3zM453.5 202c.5-.4 1-.8 1.5-1.3 4.5-4.1 8.7-8.7 12.3-13.7 9 24.7 13.6 50.6 13.6 77.3 0 6.9-.3 13.8-.9 20.6-1.5 16.2-11 30.2-25.5 37.4l-7.2 3.6 6.2-123.9zm-29 275.3c-.6 11-9.6 19.7-20.7 19.7s-20.1-8.6-20.7-19.7l-8.6-168.9c1.3.3 2.5.6 3.8.9 8.3 1.8 16.9 2.7 25.4 2.7 1.5 0 3.1 0 4.6-.1 8.3-.3 16.6-1.5 24.7-3.5l-8.5 168.9zm-54.4-263.9c7.7 3.8 16.1 6.3 24.6 7.3 7.7.9 15.4.6 23-.9 6.9-1.4 13.7-3.7 19.9-7 .1 0 .2-.1.3-.1l-4.1 80c-9.7 3-19.9 4.4-30.1 4.4-10.1 0-20.3-1.5-30.1-4.4l-4-79.5c.3 0 .4.1.5.2z"></path>
        </svg>
    )
} 

export default Paint
