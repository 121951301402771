import React from "react"

const Paper = () =>{
    return(
        <svg x="0" y="0" fill="currentColor" viewBox="0 0 512 512">
            <path d="M305.6 41l-15-29.9c-5-9.9-17.1-13.9-27-8.9L13.8 127.6c-9.9 5-13.9 17.1-8.9 27l41.3 82.3c1.3 2.6 3.9 4.1 6.6 4.1 1.1 0 2.2-.3 3.3-.8 3.6-1.8 5.1-6.3 3.3-9.9L18.1 148c-.6-1.3-.7-2.7-.3-4.1.4-1.4 1.4-2.5 2.7-3.1L270.2 15.3c2.6-1.3 5.9-.3 7.2 2.4l15 29.9c1.8 3.6 6.3 5.1 9.9 3.3 3.6-1.8 5.1-6.3 3.3-9.9z"></path>
            <path d="M509.1 251.8c0-.1 0-.2-.1-.2-.1-.3-.1-.6-.3-.9-.1-.3-.2-.6-.4-.9 0-.1-.1-.1-.1-.2-.2-.3-.3-.6-.5-.8l-93-122.3c-.2-.3-.4-.5-.7-.7l-.2-.2c-.2-.2-.5-.4-.7-.6-.3-.2-.5-.3-.8-.5-.1 0-.1-.1-.2-.1-.6-.3-1.2-.5-1.8-.6h-.4L228 98.9c-11-1.5-21.1 6.2-22.6 17.2l-39.7 291.6-55.2-289c-.6-2.9 1.4-5.7 4.2-6.2l274.5-52.4c1.4-.3 2.8 0 4 .8 1.2.8 2 2 2.2 3.4l8 41.7c.8 4 4.6 6.6 8.6 5.9 4-.8 6.6-4.6 5.9-8.6l-8-41.7c-1-5.3-4-9.8-8.4-12.8s-9.8-4.1-15.1-3.1L112 98c-10.9 2.1-18 12.6-16 23.5l56 293-71.9-143.2c-1.8-3.6-6.3-5.1-9.9-3.3-3.6 1.8-5.1 6.3-3.3 9.9l91.6 182.5v.3c-.4 2.9.4 5.7 2.1 8 1.8 2.3 4.3 3.8 7.2 4.2l286.1 38.9c.9.1 1.8.2 2.7.2 9.9 0 18.5-7.3 19.9-17.4l32.7-240.4v-.2-.2-.9c0-.4-.1-.8-.1-1.1zm-95.3-102.2l8.4 11 63.1 83-82.7-11.3 11.2-82.7zm48.1 343c-.4 2.9-3.1 5-6 4.6l-282.2-38.4L220 118.1c.4-2.9 3.1-5 6-4.6l174.6 23.7-12.7 93c-.5 3.9.5 7.8 2.9 10.9 2.4 3.1 5.9 5.2 9.8 5.7l93 12.7-31.7 233.1z"></path>
        </svg>
    )
} 

export default Paper
