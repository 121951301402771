import React from 'react'
import BannerFive from '../section/banner/BannerFive'
import FooterTwo from '../section/footer/FooterTwo'
import ServiceFive from '../section/service/ServiceFive'
import TeamOne from '../section/team/TeamOne'
import ProductOne from '../section/product/ProductOne'
import ReviewThree from '../section/review/ReviewThree'
import {FeatureFourAlt} from '../section/feature/FeatureFour'
import CtaFive from '../section/cta/CtaFive'
const IndexFive = (props) => {
  return (
		<div className='nk-main'>
			<BannerFive className='has-header-main-s1 bg-dark has-bg-image' id='#home' />
			<ServiceFive className='section-service pb-0' id='#service' />
			<ProductOne className='section-product' id='#product' />

			{/* <FeatureFour className='section-feature pb-0' id='#features ' /> */}
			{/* <FeatureFourAlt className='section-feature' /> */}
			{/* <CtaFive className='section-cta is-dark has-bg-image' id='#cta' /> */}
			{/* <PricingFour className='pb-0' id='#package' /> */}
			<TeamOne className='section-team ' id='#team' />
			{/* <ReviewThree className='section-reviews' id='#reviews' /> */}
			<FooterTwo className='bg-dark is-dark' />
		</div>
	)
}

export default IndexFive
