import React from "react"

const Responsive = () =>{
    return(
        <svg x="0" y="0" fill="currentColor" viewBox="0 0 512 512">
            <path d="M483.1 57.8h-128V28.9c0-16-12.9-28.9-28.9-28.9H185.8c-16 0-28.9 12.9-28.9 28.9v28.9h-128c-16 0-28.9 13-28.9 28.9v313.8c0 16 12.9 28.9 28.9 28.9h158.3l-11.8 41.3h-35.1c-4.6 0-8.3 3.7-8.3 8.3v24.8c0 4.6 3.7 8.3 8.3 8.3h231.2c4.6 0 8.3-3.7 8.3-8.3V479c0-4.6-3.7-8.3-8.3-8.3h-35.1l-11.8-41.3h158.3c16 0 28.9-12.9 28.9-28.9V86.7c.2-15.9-12.7-28.9-28.7-28.9zM277.6 16.5l-2.8 8.3h-37.6l-2.8-8.3h43.2zM173.4 28.9c0-6.8 5.5-12.4 12.4-12.4H217l6.4 19.1c1.1 3.4 4.3 5.6 7.8 5.6h49.5c3.6 0 6.7-2.3 7.8-5.6l6.4-19.1h31.2c6.8 0 12.4 5.5 12.4 12.4v239.5c0 6.8-5.5 12.4-12.4 12.4H185.8c-6.8 0-12.4-5.5-12.4-12.4V28.9zm190 458.3v8.3H148.6v-8.3h214.8zm-170.8-16.5l11.8-41.3h103.2l11.8 41.3H192.6zm302.9-70.2c0 6.8-5.5 12.4-12.4 12.4H28.9c-6.8 0-12.4-5.5-12.4-12.4v-20.6h479v20.6zm0-37.1h-479V86.7c0-6.8 5.5-12.4 12.4-12.4h128v194.1c0 16 12.9 28.9 28.9 28.9H223v32c0 4.6 3.7 8.3 8.3 8.3 4.6 0 8.3-3.7 8.3-8.3v-32h8.3v16.5c0 4.6 3.7 8.3 8.3 8.3 4.6 0 8.3-3.7 8.3-8.3v-16.5h8.3v41.3c0 4.6 3.7 8.3 8.3 8.3s8.3-3.7 8.3-8.3v-41.3h37.2c16 0 28.9-12.9 28.9-28.9V74.3h128c6.8 0 12.4 5.5 12.4 12.4v276.7z"></path>
            <path d="M239.5 272.5h33c4.6 0 8.3-3.7 8.3-8.3 0-4.6-3.7-8.3-8.3-8.3h-33c-4.6 0-8.3 3.7-8.3 8.3 0 4.6 3.7 8.3 8.3 8.3z"></path>
        </svg>
    )
} 

export default Responsive
